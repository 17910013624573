import * as React from 'react'
import styled from 'styled-components'
import { PropsTheme } from 'theme'

interface Props {
  title: string
}

const MainTitle = styled.h1`
  font-size: ${(props: PropsTheme) => props.theme.fontSizes.lg};
  font-weight: bold;
`

export const TitlePageHeader: React.FC<Props> = ({ title }) => {
  return (
    <div>
      <MainTitle>{title}</MainTitle>
    </div>
  )
}
