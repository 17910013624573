import { NameMenu, PathMenu, RoleAction, RoleFeature } from 'utils/constants'

import {
  AuditOutlined,
  BarChartOutlined,
  CarryOutOutlined,
  EnterOutlined,
  FileSyncOutlined,
  InboxOutlined,
  PieChartOutlined,
  ReadOutlined,
  SettingOutlined,
  ShopOutlined,
  ShoppingOutlined
} from '@ant-design/icons'

import { IMenu } from './'

export const menuLists = [
  {
    label: NameMenu.SALE,
    order: 10,
    policies: [{ action: RoleAction.READ, feature: RoleFeature.REPORT }],
    icon: <PieChartOutlined />,
    menus: [
      {
        label: NameMenu.SALESLEAD_LIST,
        href: PathMenu.SALESLEAD_LIST,
        order: 10,
        policies: [{ action: RoleAction.READ, feature: RoleFeature.SALE }],
        menus: []
      },
      {
        label: NameMenu.CUSTOMER_LIST,
        href: PathMenu.CUSTOMER_LIST,
        order: 20,
        policies: [{ action: RoleAction.READ, feature: RoleFeature.SALE }],
        menus: []
      },
      // {
      //   label: NameMenu.SALES_COMMISSION_SHOW,
      //   href: PathMenu.SALES_COMMISSION_SHOW,
      //   order: 30,
      //   policies: [{ action: RoleAction.READ, feature: RoleFeature.SALE }],
      //   menus: []
      // },
      {
        label: NameMenu.SALES_COMMISSION_LIST,
        href: PathMenu.SALES_COMMISSION_LIST,
        order: 30,
        policies: [{ action: RoleAction.READ, feature: RoleFeature.SALE }],
        menus: []
      }
    ]
  },
  {
    label: NameMenu.ORDER,
    order: 20,
    policies: [{ action: RoleAction.READ, feature: RoleFeature.ORDER }],
    icon: <CarryOutOutlined />,
    menus: [
      {
        label: NameMenu.ORDER_LIST,
        href: PathMenu.ORDER_LIST,
        order: 10,
        policies: [{ action: RoleAction.READ, feature: RoleFeature.ORDER }],
        menus: []
      },
      {
        label: NameMenu.ORDER_WAIT_FOR_PRODUCTION,
        href: PathMenu.ORDER_WAIT_FOR_PRODUCTION,
        order: 20,
        policies: [{ action: RoleAction.READ, feature: RoleFeature.ORDER }],
        menus: []
      },
      {
        label: NameMenu.ORDER_WAIT_FOR_APPROVAL,
        href: PathMenu.ORDER_WAIT_FOR_APPROVAL,
        order: 20,
        policies: [{ action: RoleAction.READ, feature: RoleFeature.ORDER }],
        menus: []
      },

      {
        label: NameMenu.PREORDER,
        href: PathMenu.PREORDER,
        order: 30,
        policies: [{ action: RoleAction.READ, feature: RoleFeature.ORDER }],
        menus: []
      },
      {
        label: NameMenu.ORDER_CHOOSE_LABEL,
        href: PathMenu.ORDER_CHOOSE_LABEL,
        order: 40,
        policies: [{ action: RoleAction.READ, feature: RoleFeature.ORDER }],
        menus: []
      },
      {
        label: NameMenu.ORDER_LIST_RETURN,
        href: PathMenu.ORDER_LIST_RETURN,
        order: 50,
        policies: [{ action: RoleAction.READ, feature: RoleFeature.ORDER }],
        menus: []
      },
      {
        label: NameMenu.ORDER_LIST_WAIT_RETURN,
        href: PathMenu.ORDER_LIST_WAIT_RETURN,
        order: 60,
        policies: [{ action: RoleAction.READ, feature: RoleFeature.ORDER }],
        menus: []
      },
      {
        label: NameMenu.TICKET_LIST,
        href: PathMenu.TICKET_LIST,
        order: 70,
        policies: [{ action: RoleAction.READ, feature: RoleFeature.ORDER }],
        menus: []
      },
      {
        label: NameMenu.ORDER_CS_LIST,
        href: PathMenu.ORDER_CS_LIST,
        order: 70,
        policies: [{ action: RoleAction.READ, feature: RoleFeature.ORDER }],
        menus: []
      },
      {
        label: NameMenu.ORDER_CLAIM_LIST,
        href: PathMenu.ORDER_CLAIM_LIST,
        order: 800,
        policies: [{ action: RoleAction.READ, feature: RoleFeature.ORDER }],
        menus: []
      }
    ]
  },
  {
    label: NameMenu.PRODUCT,
    order: 30,
    policies: [{ action: RoleAction.READ, feature: RoleFeature.PRODUCT }],
    icon: <InboxOutlined />,
    menus: [
      {
        label: NameMenu.PRODUCT_RECOMMENDATION,
        href: PathMenu.PRODUCT_RECOMMENDATION,
        order: 10,
        policies: [{ action: RoleAction.READ, feature: RoleFeature.PRODUCT }],
        icon: ''
      },
      {
        label: NameMenu.PRODUCT_LIST,
        href: PathMenu.PRODUCT_LIST,
        order: 10,
        policies: [{ action: RoleAction.READ, feature: RoleFeature.PRODUCT }],
        icon: ''
      },
      {
        label: NameMenu.BOOTH_STOCK_LIST,
        href: PathMenu.BOOTH_STOCK_LIST,
        order: 15,
        policies: [{ action: RoleAction.READ, feature: RoleFeature.PRODUCT }],
        icon: ''
      },
      {
        label: NameMenu.PRODUCT_CATEGORY,
        href: PathMenu.PRODUCT_CATEGORY,
        order: 20,
        policies: [{ action: RoleAction.READ, feature: RoleFeature.PRODUCT }],
        icon: ''
      },
      {
        label: NameMenu.WAREHOUSE_LIST,
        href: PathMenu.WAREHOUSE_LIST,
        order: 30,
        policies: [{ action: RoleAction.READ, feature: RoleFeature.PRODUCT }],
        icon: ''
      },
      {
        label: NameMenu.PRODUCT_FAKE_REFILL_STOCK,
        href: PathMenu.PRODUCT_FAKE_REFILL_STOCK,
        order: 40,
        policies: [{ action: RoleAction.READ, feature: RoleFeature.PRODUCT }],
        icon: ''
      },
      // {
      //   label: NameMenu.PRODUCT_TIMELINE,
      //   href: PathMenu.PRODUCT_TIMELINE,
      //   order: 50,
      //   policies: [{ action: RoleAction.READ, feature: RoleFeature.PRODUCT }],
      //   icon: ''
      // },
      {
        label: NameMenu.PRODUCT_TRACKING,
        href: PathMenu.PRODUCT_TRACKING,
        order: 50,
        policies: [{ action: RoleAction.READ, feature: RoleFeature.PRODUCT }],
        icon: ''
      },
      {
        label: NameMenu.GENERATE_PRODUCT_GENERIC_QR_CODE,
        href: PathMenu.GENERATE_PRODUCT_GENERIC_QR_CODE,
        order: 60,
        policies: [{ action: RoleAction.READ, feature: RoleFeature.PRODUCT }],
        icon: ''
      }
    ]
  },
  // TODO edit RoleFeature.REPORT to RoleFeature.PURCHASE_ORDER
  {
    label: NameMenu.PURCHASE_ORDER,
    order: 40,
    policies: [{ action: RoleAction.READ, feature: RoleFeature.REPORT }],
    icon: <ShoppingOutlined />,
    menus: [
      {
        label: NameMenu.SUPPLIER_LIST,
        href: PathMenu.SUPPLIER_LIST,
        order: 10,
        policies: [{ action: RoleAction.READ, feature: RoleFeature.REPORT }],
        menus: []
      },
      {
        label: NameMenu.PURCHASE_REQUISITION_LIST,
        href: PathMenu.PURCHASE_REQUISITION_LIST,
        order: 20,
        policies: [{ action: RoleAction.READ, feature: RoleFeature.SALE }],
        menus: []
      },
      {
        label: NameMenu.PURCHASE_ORDER_LIST,
        href: PathMenu.PURCHASE_ORDER_LIST,
        order: 30,
        policies: [{ action: RoleAction.READ, feature: RoleFeature.SALE }],
        menus: []
      },
      {
        label: NameMenu.PURCHASE_ORDER_ACCOUNT_LIST,
        href: PathMenu.PURCHASE_ORDER_ACCOUNT_LIST,
        order: 40,
        policies: [{ action: RoleAction.READ, feature: RoleFeature.REPORT }],
        menus: []
      }
    ]
  },
  {
    label: NameMenu.STOCK,
    href: PathMenu.STOCK,
    order: 50,
    policies: [{ action: RoleAction.READ, feature: RoleFeature.STOCK }],
    icon: <FileSyncOutlined />,
    menus: [
      {
        label: NameMenu.PRODUCT_TRACKING_CREATE,
        href: PathMenu.PRODUCT_TRACKING_CREATE,
        order: 2,
        policies: [{ action: RoleAction.READ, feature: RoleFeature.STOCK }],
        icon: ''
      },
      {
        label: NameMenu.PRODUCT_TRACKING_SCAN_CHANGE_ACTIVITY,
        href: PathMenu.PRODUCT_TRACKING_SCAN_CHANGE_ACTIVITY,
        order: 10,
        policies: [{ action: RoleAction.READ, feature: RoleFeature.STOCK }],
        icon: ''
      },
      {
        label: NameMenu.PRODUCT_TRACKING_SCAN_WAIT_FOR_QC_ACTIVITY,
        href: PathMenu.PRODUCT_TRACKING_SCAN_WAIT_FOR_QC_ACTIVITY,
        order: 12,
        policies: [{ action: RoleAction.READ, feature: RoleFeature.STOCK }],
        icon: ''
      },
      {
        label: NameMenu.PRODUCTION_PLAN,
        href: PathMenu.PRODUCTION_PLAN,
        order: 15,
        policies: [{ action: RoleAction.READ, feature: RoleFeature.STOCK }],
        icon: ''
      },
      {
        label: NameMenu.PRODUCTION_ORDER_LIST,
        href: PathMenu.PRODUCTION_ORDER_LIST,
        order: 15,
        policies: [{ action: RoleAction.READ, feature: RoleFeature.STOCK }],
        icon: ''
      },
      {
        label: NameMenu.NP_ORDER_LIST,
        href: PathMenu.NP_ORDER,
        order: 16,
        policies: [{ action: RoleAction.READ, feature: RoleFeature.STOCK }],
        icon: ''
      },
      {
        label: NameMenu.FINISHED_GOODS_DEPOSIT_LIST,
        href: PathMenu.FINISHED_GOODS_DEPOSIT_LIST,
        order: 17,
        policies: [{ action: RoleAction.READ, feature: RoleFeature.STOCK }],
        icon: ''
      },
      {
        label: NameMenu.RAW_MATERIAL_DEPOSIT_LIST,
        href: PathMenu.RAW_MATERIAL_DEPOSIT_LIST,
        order: 18,
        policies: [{ action: RoleAction.READ, feature: RoleFeature.STOCK }],
        icon: ''
      },
      {
        label: NameMenu.RAW_MATERIAL_WITHDRAW_LIST,
        href: PathMenu.RAW_MATERIAL_WITHDRAW_LIST,
        order: 19,
        policies: [{ action: RoleAction.READ, feature: RoleFeature.STOCK }],
        icon: ''
      },
      {
        label: NameMenu.TRANSFER_IN_INVENTORY_ORDER_LIST,
        href: PathMenu.TRANSFER_IN_INVENTORY_ORDER_LIST,
        order: 20,
        policies: [{ action: RoleAction.READ, feature: RoleFeature.STOCK }],
        icon: ''
      },
      {
        label: NameMenu.UNIVERSAL_TF,
        href: PathMenu.UNIVERSAL_TF,
        order: 16,
        policies: [{ action: RoleAction.CREATE, feature: RoleFeature.STOCK }],
        icon: ''
      },
      {
        label: NameMenu.TRANSFER_OUT_INVENTORY_ORDER_LIST,
        href: PathMenu.TRANSFER_OUT_INVENTORY_ORDER_LIST,
        order: 20,
        policies: [{ action: RoleAction.READ, feature: RoleFeature.STOCK }],
        icon: ''
      },
      {
        label: NameMenu.TRANSFER_INTERNAL_ORDER_LIST,
        href: PathMenu.TRANSFER_INTERNAL_ORDER_LIST,
        order: 20,
        policies: [{ action: RoleAction.READ, feature: RoleFeature.STOCK }],
        icon: ''
      },
      {
        label: NameMenu.PRODUCTION_ORDER_RAW_MATERIAL_WITHDRAW_LIST,
        href: PathMenu.PRODUCTION_ORDER_RAW_MATERIAL_WITHDRAW_LIST,
        order: 20,
        policies: [{ action: RoleAction.READ, feature: RoleFeature.STOCK }],
        icon: ''
      },
      {
        label: NameMenu.REDUCE_STOCK_LIST,
        href: PathMenu.REDUCE_STOCK_LIST,
        order: 30,
        policies: [{ action: RoleAction.READ, feature: RoleFeature.STOCK }],
        icon: ''
      },
      {
        label: NameMenu.ADD_STOCK_LIST,
        href: PathMenu.ADD_STOCK_LIST,
        order: 40,
        policies: [{ action: RoleAction.READ, feature: RoleFeature.STOCK }],
        icon: ''
      },
      {
        label: NameMenu.AUDIT_CYCLE_CHECK_LIST,
        href: PathMenu.AUDIT_CYCLE_CHECK_LIST,
        order: 45,
        policies: [{ action: RoleAction.READ, feature: RoleFeature.STOCK }],
        icon: ''
      },
      {
        label: NameMenu.INSPECTION_STOCK_LIST,
        href: PathMenu.INSPECTION_STOCK_LIST,
        order: 50,
        policies: [{ action: RoleAction.READ, feature: RoleFeature.STOCK }],
        icon: ''
      },
      {
        label: NameMenu.TRANSFER_ORDER_LIST,
        href: PathMenu.TRANSFER_ORDER_LIST,
        order: 60,
        policies: [{ action: RoleAction.READ, feature: RoleFeature.STOCK }],
        icon: ''
      },
      {
        label: NameMenu.PRODUCT_ISSUE_REPORT_LIST,
        href: PathMenu.PRODUCT_ISSUE_REPORT_LIST,
        order: 70,
        policies: [{ action: RoleAction.READ, feature: RoleFeature.STOCK }],
        icon: ''
      },
      {
        label: NameMenu.STOCK_MOVEMENT_BOOTH,
        href: PathMenu.STOCK_MOVEMENT_BOOTH,
        order: 80,
        policies: [{ action: RoleAction.READ, feature: RoleFeature.STOCK }],
        icon: ''
      },
      {
        label: NameMenu.STOCK_MOVEMENT_FACTORY,
        href: PathMenu.STOCK_MOVEMENT_FACTORY,
        order: 80,
        policies: [{ action: RoleAction.READ, feature: RoleFeature.STOCK }],
        icon: ''
      },
      {
        label: NameMenu.QR_CODE_TRACKING,
        href: PathMenu.QR_CODE_TRACKING,
        order: 90,
        policies: [{ action: RoleAction.READ, feature: RoleFeature.STOCK }],
        icon: ''
      }
    ]
  },
  {
    label: NameMenu.PICKING,
    href: PathMenu.PICKING,
    order: 60,
    policies: [{ action: RoleAction.READ, feature: RoleFeature.ORDER }],
    icon: <ShopOutlined />,
    menus: [
      {
        label: NameMenu.PICKING_ORDER,
        href: PathMenu.PICKING_ORDER,
        order: 10,
        policies: [{ action: RoleAction.READ, feature: RoleFeature.ORDER }],
        menus: []
      },
      {
        label: NameMenu.PICKING_LISTS,
        href: PathMenu.PICKING_LISTS,
        order: 20,
        policies: [{ action: RoleAction.READ, feature: RoleFeature.ORDER }],
        menus: []
      },
      {
        label: NameMenu.PACKING_ORDERS_PREPICK,
        href: PathMenu.PACKING_ORDERS_PREPICK,
        order: 30,
        policies: [{ action: RoleAction.READ, feature: RoleFeature.ORDER }],
        menus: []
      },
      {
        label: NameMenu.SCAN_PICKING_LIST,
        href: PathMenu.SCAN_PICKING_LIST,
        order: 20,
        policies: [{ action: RoleAction.READ, feature: RoleFeature.ORDER }],
        menus: []
      },
      {
        label: NameMenu.PACKING_ORDERS_PREPARED,
        href: PathMenu.PACKING_ORDERS_PREPARED,
        order: 35,
        policies: [{ action: RoleAction.READ, feature: RoleFeature.ORDER }],
        menus: []
      },
      {
        label: NameMenu.SCAN_PRINTING,
        href: PathMenu.SCAN_PRINTING,
        order: 40,
        policies: [{ action: RoleAction.READ, feature: RoleFeature.ORDER }],
        menus: []
      },
      {
        label: NameMenu.SCAN_PRINTING_ONE_N,
        href: PathMenu.SCAN_PRINTING_ONE_N,
        order: 40,
        policies: [{ action: RoleAction.READ, feature: RoleFeature.ORDER }],
        menus: []
      },
      {
        label: NameMenu.SCAN_PACKED,
        href: PathMenu.SCAN_PACKED,
        order: 42,
        policies: [{ action: RoleAction.READ, feature: RoleFeature.ORDER }],
        menus: []
      },
      {
        label: NameMenu.PACKING_ORDERS,
        href: PathMenu.PACKING_ORDERS,
        order: 45,
        policies: [{ action: RoleAction.READ, feature: RoleFeature.ORDER }],
        menus: []
      },
      {
        label: NameMenu.BOARDED_ORDER_LIST,
        href: PathMenu.BOARDED_EVIDENCE_LIST,
        order: 50,
        policies: [{ action: RoleAction.READ, feature: RoleFeature.ORDER }],
        menus: []
      },
      {
        label: NameMenu.CHANGE_ORDER_STATE,
        href: PathMenu.CHANGE_ORDER_STATE,
        order: 60,
        policies: [{ action: RoleAction.READ, feature: RoleFeature.ORDER }],
        menus: []
      },
      {
        label: NameMenu.ORDER_LIST_PACKING_HISTORY,
        href: PathMenu.ORDER_LIST_PACKING_HISTORY,
        order: 70,
        policies: [{ action: RoleAction.READ, feature: RoleFeature.ORDER }],
        menus: []
      },
      {
        label: NameMenu.CHANGE_DELIVERY_CHANNEL,
        href: PathMenu.CHANGE_DELIVERY_CHANNEL,
        order: 80,
        policies: [{ action: RoleAction.READ, feature: RoleFeature.ORDER }],
        menus: []
      },
      {
        label: NameMenu.DELIVERY_FLEET_LIST,
        href: PathMenu.DELIVERY_FLEET_LIST,
        order: 90,
        policies: [{ action: RoleAction.READ, feature: RoleFeature.ORDER }],
        menus: []
      },
      {
        label: NameMenu.GENERAL_VEHICLE_USE,
        href: PathMenu.GENERAL_VEHICLE_USE_LIST,
        order: 91,
        policies: [{ action: RoleAction.READ, feature: RoleFeature.ORDER }],
        menus: []
      },
      {
        label: NameMenu.FAKE_SHIPPED,
        href: PathMenu.FAKE_SHIPPED,
        order: 90,
        policies: [{ action: RoleAction.READ, feature: RoleFeature.ORDER }],
        menus: []
      }
    ]
  },
  {
    label: NameMenu.REPORT,
    order: 70,
    policies: [{ action: RoleAction.READ, feature: RoleFeature.REPORT }],
    icon: <BarChartOutlined />,
    menus: [
      {
        label: NameMenu.ORDER_FLEET_DASHBOARD,
        href: PathMenu.ORDER_FLEET_DASHBOARD,
        order: 10,
        policies: [{ action: RoleAction.READ, feature: RoleFeature.REPORT }],
        menus: []
      },
      {
        label: NameMenu.BOOTH_EVENT_LIST,
        href: PathMenu.BOOTH_EVENT_LIST,
        order: 10,
        policies: [{ action: RoleAction.READ, feature: RoleFeature.REPORT }],
        menus: []
      },
      {
        label: NameMenu.PRODUCT_REPORT,
        href: PathMenu.PRODUCT_REPORT,
        order: 10,
        policies: [{ action: RoleAction.READ, feature: RoleFeature.REPORT }],
        menus: []
      },
      {
        label: NameMenu.STAFF_JOB_REPORT_LIST,
        href: PathMenu.STAFF_JOB_REPORT_LIST,
        order: 20,
        policies: [{ action: RoleAction.READ, feature: RoleFeature.REPORT }],
        menus: []
      },
      {
        label: NameMenu.PRODUCTION_ORDER_BY_JOB_DETAILS_REPORT,
        href: PathMenu.PRODUCTION_ORDER_BY_JOB_DETAILS_REPORT,
        order: 25,
        policies: [{ action: RoleAction.READ, feature: RoleFeature.REPORT }],
        menus: []
      },
      {
        label: NameMenu.STAFF_CONTROL_ROOM,
        href: PathMenu.STAFF_CONTROL_ROOM,
        order: 30,
        policies: [{ action: RoleAction.READ, feature: RoleFeature.REPORT }],
        menus: []
      },
      {
        label: NameMenu.SALARY_REPORT,
        href: PathMenu.SALARY_REPORT,
        order: 40,
        policies: [{ action: RoleAction.READ, feature: RoleFeature.REPORT }],
        menus: []
      }
    ]
  },
  {
    label: NameMenu.TECHNICAL_DATA_SHEET,
    href: PathMenu.TECHNICAL_DATA_SHEET,
    order: 80,
    policies: [{ action: RoleAction.READ, feature: RoleFeature.TDS }], // should have own action and feature
    icon: <ReadOutlined />,
    menus: [
      {
        label: NameMenu.TECHNICAL_DATA_SHEET,
        href: PathMenu.TECHNICAL_DATA_SHEET,
        order: 80,
        policies: [{ action: RoleAction.READ, feature: RoleFeature.TDS }], // should have own action and feature
        icon: <ReadOutlined />
      }
    ]
  },
  {
    label: NameMenu.WORKSPACE_SETTING,
    href: PathMenu.WORKSPACE_SETTING,
    order: 80,
    policies: [{ action: RoleAction.READ, feature: RoleFeature.SETTING }],
    icon: <SettingOutlined />,
    menus: [
      {
        label: NameMenu.WORKSPACE_SHOW,
        href: PathMenu.WORKSPACE_SHOW,
        order: 10,
        policies: [{ action: RoleAction.READ, feature: RoleFeature.SETTING }],
        icon: ''
      },
      {
        label: NameMenu.MEMBER_LIST,
        href: PathMenu.MEMBER_LIST,
        order: 20,
        policies: [{ action: RoleAction.READ, feature: RoleFeature.SETTING }],
        icon: ''
      },
      {
        label: NameMenu.STAFF_LIST,
        href: PathMenu.STAFF_LIST,
        order: 25,
        policies: [{ action: RoleAction.READ, feature: RoleFeature.SETTING }],
        icon: ''
      },
      {
        label: NameMenu.STAFF_ROLE_LIST,
        href: PathMenu.STAFF_ROLE_LIST,
        order: 26,
        policies: [{ action: RoleAction.READ, feature: RoleFeature.SETTING }],
        icon: ''
      },
      {
        label: NameMenu.STAFF_ROLE_PERMISSIONS_CONFIG,
        href: PathMenu.STAFF_ROLE_PERMISSIONS_CONFIG,
        order: 27,
        policies: [{ action: RoleAction.READ, feature: RoleFeature.SETTING }],
        icon: ''
      },
      {
        label: NameMenu.ROLE_LIST,
        href: PathMenu.ROLE_LIST,
        order: 30,
        policies: [{ action: RoleAction.READ, feature: RoleFeature.SETTING }],
        icon: ''
      },
      {
        label: NameMenu.BANK_ACCOUNT,
        href: PathMenu.BANK_ACCOUNT,
        order: 40,
        policies: [{ action: RoleAction.READ, feature: RoleFeature.SETTING }],
        icon: ''
      },
      {
        label: NameMenu.TRCLOUD_SOURCE,
        href: PathMenu.TRCLOUD_SOURCE,
        order: 40,
        policies: [{ action: RoleAction.READ, feature: RoleFeature.SETTING }],
        icon: ''
      },
      {
        label: NameMenu.TRCLOUD_LOG,
        href: PathMenu.TRCLOUD_LOG,
        order: 50,
        policies: [{ action: RoleAction.READ, feature: RoleFeature.SETTING }],
        icon: ''
      },
      {
        label: NameMenu.ORDER_SOURCE_LIST,
        href: PathMenu.ORDER_SOURCE_LIST,
        order: 60,
        policies: [{ action: RoleAction.READ, feature: RoleFeature.SETTING }],
        icon: ''
      },
      {
        label: NameMenu.BOOTH_LOCATION_LIST,
        href: PathMenu.BOOTH_LOCATION_LIST,
        order: 60,
        policies: [{ action: RoleAction.READ, feature: RoleFeature.SETTING }],
        icon: ''
      },
      {
        label: NameMenu.DELIVERY_CHANNEL_LIST,
        href: PathMenu.DELIVERY_CHANNEL_LIST,
        order: 70,
        policies: [{ action: RoleAction.READ, feature: RoleFeature.SETTING }],
        icon: ''
      },
      {
        label: NameMenu.VEHICLE_LIST,
        href: PathMenu.VEHICLE_LIST,
        order: 80,
        policies: [{ action: RoleAction.READ, feature: RoleFeature.SETTING }],
        icon: ''
      },
      {
        label: NameMenu.SETTING_OTHERS,
        href: PathMenu.SETTING_OTHERS,
        order: 80,
        policies: [{ action: RoleAction.READ, feature: RoleFeature.SETTING }],
        icon: '',
        menus: [
          {
            label: NameMenu.PRIVACY_POLICY,
            href: PathMenu.PRIVACY_POLICY,
            order: 10,
            policies: [{ action: RoleAction.READ, feature: RoleFeature.SETTING }],
            icon: ''
          }
        ]
      }
    ]
  },
  {
    label: NameMenu.SHOPEE_CONFIG,
    href: PathMenu.SHOPEE_CONFIG,
    order: 80,
    policies: [{ action: RoleAction.READ, feature: RoleFeature.SETTING }],
    icon: <SettingOutlined />,
    menus: [
      {
        label: NameMenu.SHOPEE_CATEGORY_FEE,
        href: PathMenu.SHOPEE_CATEGORY_FEE,
        order: 20,
        policies: [{ action: RoleAction.READ, feature: RoleFeature.PRODUCT }],
        icon: ''
      },
      {
        label: NameMenu.SHOPEE_PROMOTIONS,
        href: PathMenu.SHOPEE_PROMOTIONS,
        order: 10,
        policies: [{ action: RoleAction.READ, feature: RoleFeature.SETTING }],
        icon: ''
      },
      {
        label: NameMenu.SHOPEE_PAYMENT_METHODS,
        href: PathMenu.SHOPEE_PAYMENT_METHODS,
        order: 10,
        policies: [{ action: RoleAction.READ, feature: RoleFeature.SETTING }],
        icon: ''
      }
    ]
  },
  {
    label: NameMenu.LAZADA_CONFIG,
    href: PathMenu.LAZADA_CONFIG,
    order: 80,
    policies: [{ action: RoleAction.READ, feature: RoleFeature.SETTING }],
    icon: <SettingOutlined />,
    menus: [
      {
        label: NameMenu.LAZADA_CATEGORY_FEE,
        href: PathMenu.LAZADA_CATEGORY_FEE,
        order: 10,
        policies: [{ action: RoleAction.READ, feature: RoleFeature.SETTING }],
        icon: ''
      },
      {
        label: NameMenu.LAZADA_TRANSACTION_FEE,
        href: PathMenu.LAZADA_TRANSACTION_FEE,
        order: 10,
        policies: [{ action: RoleAction.READ, feature: RoleFeature.SETTING }],
        icon: ''
      }
    ]
  },
  {
    label: NameMenu.ACCOUNTING,
    href: PathMenu.ACCOUNTING,
    order: 80,
    policies: [{ action: RoleAction.READ, feature: RoleFeature.SETTING }],
    icon: <SettingOutlined />,
    menus: [
      {
        label: NameMenu.ACCOUNTING,
        href: PathMenu.ACCOUNTING, //'https://docs.google.com/document/d/1-65rcx92C4xGQalu6bE4yoxALyQLoeNsgoD_UTUOfW8/edit',
        order: 10,
        policies: [{ action: RoleAction.READ, feature: RoleFeature.SETTING }],
        // target: '__blank',
        icon: ''
      },
      {
        label: NameMenu.ORDER_WITH_PAYMENT,
        href: PathMenu.ORDER_WITH_PAYMENT,
        order: 10,
        policies: [{ action: RoleAction.READ, feature: RoleFeature.SETTING }],
        icon: ''
      }
    ]
  },
  {
    label: NameMenu.AUDITING,
    href: PathMenu.AUDITING,
    order: 80,
    policies: [{ action: RoleAction.READ, feature: RoleFeature.SETTING }],
    icon: <AuditOutlined />,
    menus: [
      {
        label: NameMenu.STATEMENT_MATCHING_BANK_TRANSACTION,
        href: PathMenu.STATEMENT_MATCHING_BANK_TRANSACTION,
        order: 10,
        policies: [{ action: RoleAction.READ, feature: RoleFeature.SETTING }],
        icon: ''
      },
      {
        label: NameMenu.STATEMENT_MATCHING_MATCH,
        href: PathMenu.STATEMENT_MATCHING_MATCH,
        order: 10,
        policies: [{ action: RoleAction.READ, feature: RoleFeature.SETTING }],
        icon: ''
      },
      {
        label: NameMenu.COD_MATCHING,
        href: PathMenu.COD_MATCHING,
        order: 10,
        policies: [{ action: RoleAction.READ, feature: RoleFeature.SETTING }],
        icon: ''
      }
    ]
  },

  {
    label: NameMenu.STOCK_PRODUCT,
    href: PathMenu.STOCK_PRODUCT,
    order: 100,
    policies: [{ action: RoleAction.READ, feature: RoleFeature.SETTING }],
    icon: <EnterOutlined />,
    menus: []
  }
] as IMenu[]
