import { FC, useEffect } from 'react'
import Head from 'next/head'

interface BrowserTitleProps {
  page?: string
  companyName?: string
}
export const BrowserTitle: FC<BrowserTitleProps> = ({ page, companyName = 'FutureMakers' }) => {
  const adjustedCompanyName =
    process.env.NEXT_PUBLIC_NODE_ENV !== 'production' ? `devMode | ${companyName}` : `${companyName}`

  if (page && adjustedCompanyName) {
    return (
      <Head>
        <title>{`${page} | ${adjustedCompanyName}`}</title>
      </Head>
    )
  } else {
    return (
      <Head>
        <title>{`${adjustedCompanyName}`}</title>
      </Head>
    )
  }
}

export const useAppendTitlePrefix = (title: string): void => {
  useEffect(() => {
    document.title = `${title} - ${document.title}`
  }, [title])
}
