import React from 'react'
import { useRouter } from 'next/router'
import { Alert, AlertProps } from 'antd'
import clsx from 'clsx'
import { TextSm } from 'components/Typography'
import useCapsLock from 'hooks/useCapsLock'

const isDevelopment = ['staging', 'development'].includes(process.env.NEXT_PUBLIC_NODE_ENV)

interface CapsLockAlertProps extends AlertProps {}

const CapsLockAlert: React.FC<CapsLockAlertProps> = ({ message, type, showIcon = true, ...props }) => {
  /** ------------------------------ Hooks ------------------------------ */
  const router = useRouter()
  const isCapsLockOn = useCapsLock()

  const isStockPages = router.pathname.startsWith('/stock/')
  const isDynamicRoute = router.pathname.includes('[') && router.pathname.includes(']')
  const isScanPages = router.pathname.includes('/scan')

  /** ------------------------------ UI ------------------------------ */
  if ((isStockPages && isDynamicRoute) || isScanPages) {
    return (
      <div
        className={clsx(
          'fixed right-1/2 translate-x-1/2 z-[9999]',
          isCapsLockOn ? 'block' : 'hidden',
          isDevelopment ? 'top-[32px]' : 'top-[7.5px]'
        )}
      >
        <Alert
          {...props}
          showIcon={showIcon}
          message={<TextSm>{message || 'พบการกด Caps Lock ค้างไว้ อาจส่งผลให้แสกนไม่ตรง'}</TextSm>}
          type={type || 'warning'}
        />
      </div>
    )
  } else {
    return <></>
  }
}

export default CapsLockAlert
