import * as yup from 'yup'

export const changePasswordSchema = yup.object({
  oldPassword: yup.string().required('กรุณากรอกรหัสผ่านเดิม'),
  password: yup.string().required('กรุณากรอกรหัสผ่านใหม่'),
  passwordConfirmation: yup
    .string()
    .oneOf([yup.ref('password'), null], 'รหัสผ่านไม่ตรงกัน')
    .required('กรุณากรอกยืนยันรหัสผ่าน')
})
