import { Grid, notification } from 'antd'
const { useBreakpoint } = Grid
import { Fragment } from 'react'
import { ModalWithActionButton } from 'components/Modal'
import { Form, Formik } from 'formik'
import { UPDATE_WORKSPACE_MEMBER, UpdateWorkspaceMember } from 'graphql-shared'
import { STATUS_MODAL_ALERT } from 'utils/constants'

import { useMutation } from '@apollo/client'
import { useAuth0 } from '@auth0/auth0-react'

import { DesktopProfileForm } from './desktop/ProfileForm'
import { MobileProfileForm } from './mobile/ProfileForm'

interface IProfileModal {
  onCancel: () => void
  open: boolean
}

export const ProfileModal: React.FC<IProfileModal> = ({ onCancel, open }) => {
  const { xs } = useBreakpoint()
  const { user, isLoading } = useAuth0()
  const [notificationApi, notificationContextHolder] = notification.useNotification()
  // const [updateWorkspaceMember] = useMutation<UpdateWorkspaceMember>(UPDATE_WORKSPACE_MEMBER, {
  //   onCompleted: () => {
  //     notificationApi[STATUS_MODAL_ALERT.SUCCESS]({ message: 'แก้ไขข้อมูลส่วนตัวเรียบร้อยแล้ว' })
  //     //todo sync to auth0
  //     // loginWorkspace(workspaceMember?.workspace?._id)
  //     onCancel()
  //   }
  // })

  return (
    <Fragment>
      {notificationContextHolder}
      <Formik
        initialValues={{
          photoURL: user?.picture,
          displayName: user?.nickname,
          email: user?.email
        }}
        onSubmit={(values) => {
          //todo update to auth0.
          // updateWorkspaceMember({ variables: { id: user?._id, input: values } })
        }}
      >
        {({ handleSubmit, isSubmitting }) => {
          return (
            <ModalWithActionButton
              open={open}
              width={560}
              title="ข้อมูลส่วนตัว"
              onCancel={onCancel}
              onSubmit={handleSubmit}
              loading={isSubmitting}
              justify="center"
            >
              <Form>{xs ? <MobileProfileForm /> : <DesktopProfileForm />}</Form>
            </ModalWithActionButton>
          )
        }}
      </Formik>
    </Fragment>
  )
}
