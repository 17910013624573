import { KerryTrackingStatus } from './constants'

const productionConfig = {
  warehouse: [
    { _id: '5f69a523f5d4520011918d20', name: 'คลังสินค้าเริ่มแรกสุด' },
    { _id: '60ea8dc6231a48973ee78102', name: 'deletedwarehouse' }
  ],
  //* delivery channel for kerry api
  deliveryChannelUseKerryAPI: [
    //* kerry-cod
    '61932a84adecf50011a095fc',
    //* kerry
    '6012900f75ec00581e035b38',
    '62f3186c94447a29362e21f6' //* lazada-seller-own-fleet
    // '6342ff2af6a4bf1a19a5a36e', //**shopee own fleet */,
    // '64910ecfec5dbbb502c8aead' // tiktok-seller-own-fleet
  ],
  deliveryChannelCOD: [
    //* kerry-cod
    '61932a84adecf50011a095fc',
    //* tp-cod
    '63848e43f3fbb78eebc8a0e8',
    //* scg-cod
    '60f55be361bc4e00126c0972',
    //* sb-cod,
    '66a685730723a8d8caa84743',
    //*pj-cod,
    '66a685650723a8d8caa8466a',
    //*bs-cod,
    '66a685490723a8d8caa8460c',
    //*fm-standard-cod,
    '66cd99c775ef5fe289b09f37',
    //*fm-express-cod,
    '66d6c2028a3948951842ed9f'
  ],
  deliveryChannelUseThaiParcelAPI: [
    '6012900f75ec00581e035b3c', // tp-thailand
    '638482dff3fbb78eebc8a0e7', // own-fleet-tp
    '63848e43f3fbb78eebc8a0e8' // tp-cod
  ],
  statesForPending: [KerryTrackingStatus.READY_TO_SHIP],
  statesForTransportedStatus: [
    KerryTrackingStatus.TRANSPORTING,
    KerryTrackingStatus.SHIPING,
    KerryTrackingStatus.SHIPED,
    KerryTrackingStatus.PICKS_UP_PARCEL
  ],
  statesForDeliveredStatus: [KerryTrackingStatus.SHIPED],
  statesForCancelledStatus: [],
  happyPathKerryAPI: [
    KerryTrackingStatus.READY_TO_SHIP,
    KerryTrackingStatus.PARCEL_SENDER_AT_BRANCH,
    KerryTrackingStatus.PARCEL_ARRIVED,
    KerryTrackingStatus.PICKS_UP_PARCEL,
    KerryTrackingStatus.TRANSPORTING,
    KerryTrackingStatus.SHIPING,
    KerryTrackingStatus.SHIPED
  ],
  badPathKerryAPI: [
    KerryTrackingStatus.PARCEL_RETURNING,
    KerryTrackingStatus.PARCEL_RETURNED,
    KerryTrackingStatus.RETRY_SHIPING,
    KerryTrackingStatus.TRACKING_NUMBER_NOT_FOUND
  ],
  fmDeliveryChannel: ['6200c6549feba856bc906eac'],
  boothDeliveryChannel: '6448f3e4c288e08097644c0b',
  defaultFactoryWarehouse: '662b2cf0cffd1d99e7cbac3c',
  defaultOrderWarehouse: '5f69a523f5d4520011918d20',
  defaultBoothWarehouse: '66291ba9df6e2a88a17d973c',
  zoneURL:
    'https://firebasestorage.googleapis.com/v0/b/fm-private-bucket/o/zones.json?alt=media&token=8381c672-92e9-43ae-8fef-4febb0b4510f',
  inhouseDriverEmails: ['driver-inhouse@futuremakers.co.th'],
  outsourceDriverEmails: ['driver-outsource@futuremakers.co.th'],
  manualBoothSourceRef: '64701bf04372e81811ab0e16',
  manualSourceRef: '5fe1af40badfe8d2e85b0948',
  tfwSourceRef: '663e24388a83d789d2c18f08',
  saleCategories: [
    '6614e2afe9f2749e731abbc7',
    '6614e26de9f2749e7319fc68',
    '6618d91f89844f0258b8e8db',
    '6614e26fe9f2749e7319ff4c',
    '6614e271e9f2749e7319fffa'
  ],
  claimRepairSourceRef: '66592b5be86efdf7202772fc',
  salesOnlineRole: 'sales-online'
}
const developmentConfig = {
  warehouse: [
    { _id: '5f69a523f5d4520011918d20', name: 'คลังสินค้าเริ่มแรกสุด' },
    { _id: '60ea8dc6231a48973ee78102', name: 'deletedwarehouse' },
    { _id: '61f3aa468e9c4f0321c5ed29', name: 'ทดสอบ' }
  ],
  //* delivery channel for kerry api
  deliveryChannelUseKerryAPI: [
    //* kerry-cod
    '61932a84adecf50011a095fc',
    //* kerry
    '6012900f75ec00581e035b38',
    '62f3186c94447a29362e21f6' //* lazada-seller-own-fleet,
    // '6342ff2af6a4bf1a19a5a36e', //**shopee own fleet */
    // '64910ecfec5dbbb502c8aead' // tiktok-seller-own-fleet
  ],
  deliveryChannelCOD: [
    //* kerry-cod
    '61932a84adecf50011a095fc',
    //* tp-cod
    '63848e43f3fbb78eebc8a0e8',
    //* scg-cod
    '60f55be361bc4e00126c0972',
    //* sb-cod,
    '66a685730723a8d8caa84743',
    //*pj-cod,
    '66a685650723a8d8caa8466a',
    //*bs-cod,
    '66a685490723a8d8caa8460c',
    //*fm-standard-cod,
    '66cd99c775ef5fe289b09f37',
    //*fm-express-cod,
    '66d6c2028a3948951842ed9f'
  ],
  deliveryChannelUseThaiParcelAPI: [
    '6012900f75ec00581e035b3c', // tp-thailand
    '638482dff3fbb78eebc8a0e7', // own-fleet-tp
    '63848e43f3fbb78eebc8a0e8' // tp-cod
  ],
  statesForPending: [KerryTrackingStatus.READY_TO_SHIP],
  statesForTransportedStatus: [
    KerryTrackingStatus.TRANSPORTING,
    KerryTrackingStatus.SHIPING,
    KerryTrackingStatus.SHIPED,
    KerryTrackingStatus.PICKS_UP_PARCEL
  ],
  statesForDeliveredStatus: [KerryTrackingStatus.SHIPED],
  statesForCancelledStatus: [],
  happyPathKerryAPI: [
    KerryTrackingStatus.READY_TO_SHIP,
    KerryTrackingStatus.PARCEL_SENDER_AT_BRANCH,
    KerryTrackingStatus.PARCEL_ARRIVED,
    KerryTrackingStatus.PICKS_UP_PARCEL,
    KerryTrackingStatus.TRANSPORTING,
    KerryTrackingStatus.SHIPING,
    KerryTrackingStatus.SHIPED
  ],
  badPathKerryAPI: [
    KerryTrackingStatus.PARCEL_RETURNING,
    KerryTrackingStatus.PARCEL_RETURNED,
    KerryTrackingStatus.RETRY_SHIPING,
    KerryTrackingStatus.TRACKING_NUMBER_NOT_FOUND
  ],
  fmDeliveryChannel: ['654a317783abd9f937e06cff'],
  boothDeliveryChannel: '663c51ac44c783fc6bc4cd2c',
  defaultFactoryWarehouse: '5f69a523f5d4520011918d20',
  defaultOrderWarehouse: '5f69a523f5d4520011918d20',
  defaultBoothWarehouse: '665023c56cbb2766bd100d0d',
  zoneURL:
    'https://firebasestorage.googleapis.com/v0/b/fm-private-bucket/o/zones.json?alt=media&token=8381c672-92e9-43ae-8fef-4febb0b4510f',
  inhouseDriverEmails: ['driver-inhouse@futuremakers.co.th'],
  outsourceDriverEmails: ['driver-outsource@futuremakers.co.th'],
  manualBoothSourceRef: '64701bf04372e81811ab0e16',
  manualSourceRef: '5fe1af40badfe8d2e85b0948',
  tfwSourceRef: '663d94b56b14fa49e8dab0cd',
  saleCategories: [
    '6614e2afe9f2749e731abbc7',
    '6614e26de9f2749e7319fc68',
    '6618d91f89844f0258b8e8db',
    '6614e26fe9f2749e7319ff4c',
    '6614e271e9f2749e7319fffa'
  ],
  claimRepairSourceRef: '66592b5be86efdf7202772fc',
  salesOnlineRole: 'sales-online'
}

const config = process.env.NODE_ENV !== 'production' ? developmentConfig : productionConfig
export default config
