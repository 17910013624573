import { Col, Row } from 'antd'
import { StyledInputPassword } from 'components/Input/text'
import { TextSm } from 'components/Typography'
import { StyledFormItem } from 'containers/Product/shared/product-form'
import { Field, useFormikContext } from 'formik'
import { getErrorMessageByKey, getValidateStatusByKey } from 'utils/validate'

export const DesktopChangePasswordForm: React.FC = () => {
  const { errors, touched } = useFormikContext()

  return (
    <div style={{ display: 'grid', padding: '0 80px' }}>
      <Row gutter={[36, 24]} align="middle">
        <Col xs={24} sm={24}>
          <TextSm>รหัสผ่านเดิม</TextSm>
          <Field name="oldPassword">
            {({ field, form }) => {
              return (
                <StyledFormItem
                  validateStatus={getValidateStatusByKey(errors, touched, ['oldPassword'])}
                  help={getErrorMessageByKey(errors, touched, ['oldPassword'])}
                >
                  <StyledInputPassword
                    {...field}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      form.setFieldValue(field.name, e.target.value)
                    }}
                  />
                </StyledFormItem>
              )
            }}
          </Field>
        </Col>
        <Col xs={24} sm={24}>
          <TextSm>รหัสผ่านใหม่</TextSm>
          <Field name="password">
            {({ field, form }) => {
              return (
                <StyledFormItem
                  validateStatus={getValidateStatusByKey(errors, touched, ['password'])}
                  help={getErrorMessageByKey(errors, touched, ['password'])}
                >
                  <StyledInputPassword
                    {...field}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      form.setFieldValue(field.name, e.target.value)
                    }}
                  />
                </StyledFormItem>
              )
            }}
          </Field>
        </Col>
        <Col xs={24} sm={24}>
          <TextSm>ยืนยันรหัสผ่าน</TextSm>
          <Field name="passwordConfirmation">
            {({ field, form }) => {
              return (
                <StyledFormItem
                  validateStatus={getValidateStatusByKey(errors, touched, ['passwordConfirmation'])}
                  help={getErrorMessageByKey(errors, touched, ['passwordConfirmation'])}
                >
                  <StyledInputPassword
                    {...field}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      form.setFieldValue(field.name, e.target.value)
                    }}
                  />
                </StyledFormItem>
              )
            }}
          </Field>
        </Col>
      </Row>
    </div>
  )
}
