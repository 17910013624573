import Layout, { Content } from 'antd/lib/layout/layout'
import { BrowserTitle } from 'components/Head'
import styled from 'styled-components'
import { PropsTheme } from 'theme'

import { StagingBar } from './components/StagingBar'
import { PublicUserHeader } from './main-layout/components/desktop/Header'

interface ISider extends PropsTheme {
  iscollapsed: number
}

const LayoutContentWithStyled = styled(Layout).attrs((props: ISider) => {
  return { iscollapsed: props?.iscollapsed }
})`
  margin: 120px auto 64px auto;
  min-height: 100vh;
  margin-left: calc(12% - 48px);
  margin-right: calc(12% - 48px);

  & .ant-layout-content {
    position: relative;
    width: 100%;
  }
`

interface IDesktopPublicLayout {
  children: any
  // menus: IMenu[]
  // favoriteMenus: IMenu[]
  // onClickFavoriteMenu: (favoriteMenu: string) => void
}

export const DesktopPublicLayout: React.FC<IDesktopPublicLayout> = ({ children }) => {
  const { title, route } = children.props
  return (
    <>
      <StagingBar />
      <PublicUserHeader />
      <LayoutContentWithStyled iscollapsed={0}>
        <BrowserTitle page={title} />
        <Content className={'bg-white'}>{children}</Content>
      </LayoutContentWithStyled>
    </>
  )
}
