import { useContext } from 'react'
import { Col, Row } from 'antd'
import { CheckboxStyled } from 'components/Input/checkbox'
import { TextFieldWrapper } from 'components/Input/text'
import { TextSm, TextSmMinus } from 'components/Typography'
import { Image, UploadImage } from 'components/UploadImage'
import { StyledFormItem } from 'containers/Product/shared/product-form'
import { FastField, FastFieldProps, Field, FieldProps } from 'formik'
import { ThemeContext } from 'styled-components'
import { DarkTheme, LightTheme } from 'theme'
import { TypeImage } from 'utils/constants'

export const DesktopProfileForm: React.FC = () => {
  const themeContext = useContext(ThemeContext) as LightTheme | DarkTheme

  return (
    <div style={{ display: 'grid', padding: '0 80px' }}>
      <Row gutter={[64, 32]} justify="center">
        <Col>
          <FastField name="photoURL">
            {({ field, form }: FastFieldProps) => {
              return (
                <UploadImage
                  listType="picture-card"
                  folderUpload="workspace-images"
                  images={[{ src: field?.value, imageType: TypeImage.NORMAL, order: 0 }]}
                  uploaderInfo={Array(1).fill(TypeImage.NORMAL)}
                  handleChange={(images: Image[]) => form.setFieldValue(field.name, images[0]?.src)}
                  gutter={[16, 0]}
                  justify="start"
                  turnOnLabel={false}
                  turnOnCounting={false}
                  fontSizeLabel={10}
                  width={88}
                  height={88}
                />
              )
            }}
          </FastField>
        </Col>
      </Row>
      <Row gutter={[64, 32]}>
        <Col sm={24}>
          <TextSmMinus>ชื่อผู้ใช้งาน</TextSmMinus>
          <Field name="displayName">
            {({ field }: FieldProps) => {
              return (
                <StyledFormItem>
                  <TextFieldWrapper {...field} />
                </StyledFormItem>
              )
            }}
          </Field>
        </Col>
      </Row>
      <Row gutter={[64, 32]}>
        <Col sm={24}>
          <TextSmMinus>สิทธิ์การใช้งาน</TextSmMinus>
          <StyledFormItem>
            <TextFieldWrapper value="Admin" disabled />
          </StyledFormItem>
        </Col>
      </Row>
      <Row gutter={[64, 32]}>
        <Col sm={24}>
          <TextSmMinus>อีเมล</TextSmMinus>
          <Field name="email">
            {({ field }: FieldProps) => {
              return (
                <StyledFormItem>
                  <TextFieldWrapper {...field} disabled />
                </StyledFormItem>
              )
            }}
          </Field>
        </Col>
      </Row>
      <Row gutter={[64, 32]}>
        <Col sm={24}>
          <CheckboxStyled />
          <TextSm style={{ marginLeft: 8 }} as="span">
            รับการแจ้งเตือนและข่าวสารทางอีเมล
          </TextSm>
        </Col>
      </Row>
    </div>
  )
}
