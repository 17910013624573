import { useContext, useState } from 'react'
import Image from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { Avatar, Button, Col, Dropdown, notification, Row, Spin } from 'antd'
import { Key } from 'antd/es/table/interface'
import clsx from 'clsx'
import { ImageRounded } from 'components/Image'
import { BadgeNotification } from 'components/Layout/components/BadgeNotification'
import { OldRow } from 'components/Row'
import { TextSm } from 'components/Typography'
import { GET_NOTIFICATIONS_META, GET_THIS_USER, GetnotificationsMeta, GetUser } from 'graphql-shared'
import styled, { ThemeContext } from 'styled-components'
import { DarkTheme, LightTheme, PropsTheme } from 'theme'
import { SuperAdminBadge } from 'utils/auth/user-role'
import { COMPANY_MERCHANT_NAME } from 'utils/constants'

import { DownOutlined, LogoutOutlined, UserOutlined } from '@ant-design/icons'
import { useQuery } from '@apollo/client'
import { useAuth0 } from '@auth0/auth0-react'

import { ChangePasswordModal } from '../ChangePasswordModal'
import { ProfileModal } from '../ProfileModal'

const WrapperHeader = styled.div.attrs((props: ITopHeader) => {
  return { iscollapsed: props?.iscollapsed || 0, env: props?.env }
})`
  width: ${(props) => (props.iscollapsed ? 'calc(100% - 62px)' : 'calc(100% - 230px)')};
  height: 56px;
  background: ${(props) => props.theme.colors.white};
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.06);
  padding: 12px 48px;
  position: fixed;
  z-index: 10;
  top: ${(props) => (props.env === 'staging' || props.env === 'development' ? '25px' : '0')};

  span {
    display: flex;
    justify-content: center;
    align-content: center;
  }
`

interface ITopHeader extends PropsTheme {
  iscollapsed: number
  env: string
}
interface Props {
  iscollapsed: number
}

export const Header: React.FC<Props> = ({ iscollapsed }) => {
  /* -------------------------------------------------------------------------- */
  /*                                    utils                                   */
  /* -------------------------------------------------------------------------- */
  const themeContext = useContext(ThemeContext) as LightTheme | DarkTheme
  const { user, error, isLoading, logout } = useAuth0()
  const router = useRouter()
  const [notificationApi, notificationContextHolder] = notification.useNotification()

  /* -------------------------------------------------------------------------- */
  /*                                  useState                                  */
  /* -------------------------------------------------------------------------- */
  const [profileModalVisible, setProfileModalVisible] = useState(false)
  const [changePasswordModalVisible, setChangePasswordModalVisible] = useState(false)

  /* -------------------------------------------------------------------------- */
  /*                                   graphql                                  */
  /* -------------------------------------------------------------------------- */

  const {
    data: getThisUser,
    loading: loadingThisUser,
    refetch: refetchThisUser
  } = useQuery<GetUser>(GET_THIS_USER, {
    fetchPolicy: 'no-cache',
    variables: {
      input: {
        email: user?.email
      },
      from: 'main-layout/components/desktop/header'
    },
    skip: !user
  })

  const { data: getNotificationsMeta, loading: loadingNotificationsMeta } = useQuery<GetnotificationsMeta>(
    GET_NOTIFICATIONS_META,
    {
      variables: { userId: getThisUser?.getUserWithFilter?._id },
      fetchPolicy: 'cache-first',
      pollInterval: 60000 * 5,
      // pollInterval: 60000,
      onCompleted(data) {
        // notificationApi[STATUS_MODAL_ALERT.SUCCESS]({ message: 'getNotificationsMeta' })
      },
      skip: true //!user || !getThisUser
    }
  )

  const handleMenuChange = (key: Key) => {
    switch (key) {
      case 'profile':
        setProfileModalVisible(true)
        break
      case 'change-language':
        break
      case '/api/auth/logout':
        return false
      case 'change-password':
        setChangePasswordModalVisible(true)
        break
      default:
        break
    }
  }

  if (error) throw error
  if (isLoading) return <Spin />

  return (
    <>
      {notificationContextHolder}
      {changePasswordModalVisible && (
        <ChangePasswordModal open={changePasswordModalVisible} onCancel={() => setChangePasswordModalVisible(false)} />
      )}

      {profileModalVisible && (
        <ProfileModal open={profileModalVisible} onCancel={() => setProfileModalVisible(false)} />
      )}
      <WrapperHeader iscollapsed={iscollapsed} env={process.env.NEXT_PUBLIC_NODE_ENV}>
        <OldRow gutter={12} justify="center" align="middle">
          <Col>
            <div style={{ width: 32, height: 32 }}>
              <ImageRounded src="/static/logo/image-fm-logo.png" width={32} height={32} alt="workspace-image" />
            </div>
          </Col>
          <Col flex="auto">
            <TextSm color={themeContext.fontColor.dark}>บริษัท ฟิวเจอร์ เมคเกอร์ จำกัด</TextSm>
          </Col>
          <Col style={{ marginRight: 16 }}>
            <Link href="/notifications" as={'/notifications'} legacyBehavior>
              <Button type="link" style={{ borderRadius: 360, padding: '0' }}>
                <a>
                  <BadgeNotification getNotificationsMeta={getNotificationsMeta} />
                </a>
              </Button>
            </Link>
          </Col>
          <Col>
            <Dropdown
              getPopupContainer={(el) => el.parentElement}
              menu={{
                items: [
                  {
                    key: 'profile',
                    icon: <UserOutlined />,
                    label: 'ข้อมูลส่วนตัว'
                  },
                  {
                    key: '/api/auth/logout',
                    icon: <LogoutOutlined />,
                    danger: true,
                    label: (
                      <Button
                        danger
                        type="link"
                        onClick={() => logout({ returnTo: process.env.NEXT_PUBLIC_APP_URL } as any)}
                      >
                        ออกจากระบบ
                      </Button>
                    )
                  }
                ]
              }}
              trigger={['click']}
            >
              <Row align="middle">
                <Col style={{ marginRight: 12 }}>
                  <Avatar size="default" src={user?.picture} />
                </Col>
                <Col style={{ marginRight: 8 }}>
                  <TextSm as="span" color={themeContext.fontColor.dark}>
                    {user?.nickname || user?.email}
                  </TextSm>
                  <SuperAdminBadge user={user} />
                </Col>
                <Col>
                  <DownOutlined style={{ fontSize: 8 }} />
                </Col>
              </Row>
            </Dropdown>
          </Col>
        </OldRow>
      </WrapperHeader>
    </>
  )
}

export const PublicUserHeader: React.FC = () => {
  return (
    <div
      id={'public-header'}
      className={clsx(
        'w-full bg-white h-14 flex flex-row justify-center items-center gap-4 fixed top-0 z-50 shadow',
        ['staging', 'development'].includes(process.env.NEXT_PUBLIC_NODE_ENV) ? 'mt-5' : ''
      )}
      style={{ boxShadow: '0px 4px 8px 0px #0000000D' }}
    >
      <div className={'w-8 h-8 rounded-md overflow-hidden '}>
        <Image
          src="/static/logo/FUTURELIVING_LOGO_FINAL_2024.svg"
          alt="logo"
          width={32}
          height={32}
          className={'object-cover '}
        />
      </div>
      <TextSm>{COMPANY_MERCHANT_NAME}</TextSm>
    </div>
  )
}
