import React, { useEffect, useState } from 'react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { Button, Col, Row, Spin } from 'antd'
import { MenuItem } from 'types/menu'
import config from 'utils/config'
import { PathMenu, RoleAction, RoleFeature } from 'utils/constants'

import { StarFilled, StarOutlined } from '@ant-design/icons'
import { useAuth0 } from '@auth0/auth0-react'

import { DesktopPublicLayout } from '../PublicLayout'

import { DesktopLayout } from './components/desktop/Layout'
import { menuLists } from './menu-lists'

interface Props {
  children: React.ReactNode
  host?: any
}

export interface IMenu {
  label: string
  href: string
  policies: { action: RoleAction; feature: RoleFeature }[]
  order: number
  icon?: JSX.Element
  target?: string
  menus?: IMenu[]
}

export const menuItemMapper = (
  menus: IMenu[],
  favoriteMenus?: IMenu[],
  onClickFavoriteMenu?: (favoriteMenu: string) => void
): MenuItem[] => {
  return menus?.map((data, index) => {
    if (data?.menus?.length > 0) {
      return {
        key: `${data.label}${data.href}${index}`,
        icon: data.icon,
        title: data.label,
        label: data.label,
        target: data.target,
        children: data.menus.map((subMenu, subMenuIndex) => {
          const isfavouritemenu = favoriteMenus?.some((x) => x?.label === subMenu.label)
          if (subMenu.menus?.length > 0) return menuItemMapper([data.menus[subMenuIndex]])
          else
            return {
              key: subMenu.href,
              icon: subMenu.icon,
              label: (
                <Row justify="space-between">
                  <Col flex="auto">
                    <Link href={subMenu.href} target={subMenu?.target}>
                      {' '}
                      {subMenu.label}
                    </Link>
                  </Col>
                  <Col>
                    <Button
                      onClick={() => onClickFavoriteMenu(subMenu.label)}
                      shape="circle"
                      type="text"
                      icon={isfavouritemenu ? <StarFilled /> : <StarOutlined />}
                    />
                  </Col>
                </Row>
              )
            }
        })
      }
    } else {
      if (!data.href || !data.label) throw new Error(`menu item is missing${JSON.stringify(data)}`)
      return {
        key: data.href,
        icon: data.icon,
        className: 'no-child',
        label: (
          <Link href={data.href} target={data.target}>
            {data.label}
          </Link>
        )
      } as MenuItem
    }
  })
}

export const MainLayout: React.FC<Props> = ({ children }) => {
  const router = useRouter()
  const { user, error, isLoading } = useAuth0()
  const [menus, setMenus] = useState<IMenu[]>(null)
  const [favoriteMenus, setFavoriteMenus] = useState<IMenu[]>([])

  const handleClickFavoriteMenu = (favoriteMenu: string) => {
    const favoriteMenuStorage = localStorage.getItem('favorite-menus')
    if (!favoriteMenuStorage) localStorage.setItem('favorite-menus', JSON.stringify([favoriteMenu]))
    else {
      const favoriteMenuParsed = JSON.parse(favoriteMenuStorage)
      if (!favoriteMenuParsed.some((x) => x === favoriteMenu)) {
        localStorage.setItem('favorite-menus', JSON.stringify([...favoriteMenuParsed, favoriteMenu]))
      } else {
        localStorage.setItem('favorite-menus', JSON.stringify(favoriteMenuParsed?.filter((x) => x !== favoriteMenu)))
      }
    }

    if (favoriteMenus?.every((x) => x?.label !== favoriteMenu)) {
      const menu = menus?.flatMap((x) => x?.menus)?.find((x) => x?.label === favoriteMenu)
      setFavoriteMenus([...favoriteMenus, menu])
    } else setFavoriteMenus(favoriteMenus?.filter((x) => x?.label !== favoriteMenu))
  }

  useEffect(() => {
    let isSubscribe = true
    if (isSubscribe) {
      if (user && !isLoading) {
        if (config?.outsourceDriverEmails?.includes(user?.email)) {
          router.push({ pathname: PathMenu.DELIVERY_FLEET_MOBILE })
        }

        const favoriteMenuStorage = localStorage.getItem('favorite-menus')

        if (favoriteMenuStorage) {
          const favoriteMenusParsed = JSON.parse(favoriteMenuStorage)
          const favoriteMenus = menuLists
            ?.flatMap((x) => x?.menus)
            ?.filter((x) => favoriteMenusParsed?.some((y) => y === x?.label))

          setFavoriteMenus(favoriteMenus)
        }

        setMenus(
          menuLists
            .sort((a, b) => {
              return a.order - b.order
            })
            .map((data) => {
              return {
                ...data,
                menus: data.menus.sort((a, b) => a.order - b.order)
              }
            })
        )
      }
    }
    return () => {
      isSubscribe = false
    }
  }, [user, isLoading, router])

  if (error) return <div>error loading</div>

  if (isLoading) return <Spin tip="ssr loading." />

  return (
    <DesktopLayout menus={menus} favoriteMenus={favoriteMenus} onClickFavoriteMenu={handleClickFavoriteMenu}>
      {children}
    </DesktopLayout>
  )
  // return (
  //   <>
  //     {(xs || sm || md) && !lg ? (
  //       <MobileLayout menus={menus}>{children}</MobileLayout>
  //     ) : (
  //       <DesktopLayout menus={menus} favoriteMenus={favoriteMenus} onClickFavoriteMenu={handleClickFavoriteMenu}>
  //         {children}
  //       </DesktopLayout>
  //     )}
  //   </>
  // )
}

export const MainPublicLayout: React.FC<Props> = ({ children }) => {
  return <DesktopPublicLayout>{children}</DesktopPublicLayout>
}
