import { Grid } from 'antd'
const { useBreakpoint } = Grid
import { ModalWithActionButton } from 'components/Modal'
import { Form, Formik } from 'formik'
import { useAuth } from 'hooks/auth/useAuth'

import { changePasswordSchema } from '../yup-schema'

import { DesktopChangePasswordForm } from './desktop/ChangePasswordForm'
import { MobileChangePasswordForm } from './mobile/ChangePasswordForm'

interface IChangePasswordModal {
  onCancel: () => void
  open: boolean
}

export const ChangePasswordModal: React.FC<IChangePasswordModal> = ({ onCancel, open }) => {
  const { xs } = useBreakpoint()
  const { updatePassword, loading } = useAuth()

  return (
    <Formik
      initialValues={{
        oldPassword: '',
        password: '',
        passwordConfirmation: ''
      }}
      validationSchema={changePasswordSchema}
      onSubmit={async (values) => {
        const { oldPassword, password } = values
        await updatePassword(oldPassword, password)
        onCancel()
      }}
    >
      {({ handleSubmit, isSubmitting }) => {
        return (
          <ModalWithActionButton
            open={open}
            width={560}
            title="เปลี่ยนรหัสผ่าน"
            onCancel={onCancel}
            onSubmit={handleSubmit}
            loading={isSubmitting}
            justify="center"
          >
            <Form>{xs ? <MobileChangePasswordForm /> : <DesktopChangePasswordForm />}</Form>
          </ModalWithActionButton>
        )
      }}
    </Formik>
  )
}
