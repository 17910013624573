import * as React from 'react'
import styled, { keyframes } from 'styled-components'
const GradientAnimation = keyframes`
0% {
 background-position: 0% 50%;
}
50% {
 background-position: 100% 50%;
}
100% {
 background-position: 0% 50%;
}
`
const StyledStagingBar = styled.div<{ env: string }>`
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;
  text-align: center;
  background: ${(props) =>
    props.env === 'staging'
      ? 'linear-gradient(270deg, #0065f2, #89d1f9)'
      : 'linear-gradient(270deg, rgba(203,100,45,1) 0%, rgba(253,187,45,1) 100%)'};
  color: white;
  font-size: 1em;
  background-size: 400% 400%;
  animation-name: ${GradientAnimation};
  animation-duration: ${(props) => (props.env === 'staging' ? '1s' : '5s')};
  animation-iteration-count: infinite;
`
export const StagingBar: React.FC = () => {
  return (
    <>
      {process.env.NEXT_PUBLIC_NODE_ENV === 'staging' && (
        <StyledStagingBar env={process.env.NEXT_PUBLIC_NODE_ENV}>STAGING SERVER</StyledStagingBar>
      )}
      {process.env.NEXT_PUBLIC_NODE_ENV === 'development' && (
        <StyledStagingBar env={process.env.NEXT_PUBLIC_NODE_ENV}>DEVELOPMENT SERVER</StyledStagingBar>
      )}
    </>
  )
}
