import { useEffect, useState } from 'react'

const useCapsLock = (): boolean => {
  const [isCapsLockOn, setIsCapsLockOn] = useState<boolean>(false)

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      try {
        setIsCapsLockOn(event?.getModifierState?.('CapsLock'))
      } catch (error) {
        console.error(error)
      }
    }

    const handleKeyUp = (event: KeyboardEvent) => {
      try {
        if (!event?.getModifierState?.('CapsLock')) {
          setIsCapsLockOn(false)
        }
      } catch (error) {
        console.error(error)
      }
    }

    window.addEventListener('keydown', handleKeyDown)
    window.addEventListener('keyup', handleKeyUp)

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
      window.removeEventListener('keyup', handleKeyUp)
    }
  }, [])

  return isCapsLockOn
}

export default useCapsLock
