import { useRouter } from 'next/router'
import { Button, Col, Menu } from 'antd'
import { ImageWithDefault } from 'components/Image'
import { OldRow } from 'components/Row'
import styled from 'styled-components'
import { PropsTheme } from 'theme'

import { MenuOutlined } from '@ant-design/icons'

import { IMenu, menuItemMapper } from '../..'

const MenuStyled = styled(Menu).attrs((props: IMenuStyled) => {
  return {
    iscollapsed: props?.iscollapsed,
    isfavouritemenu: props?.isfavouritemenu
  }
})`
  width: ${(props) => (props.iscollapsed ? '62px' : '230px')};

  & .ant-menu-submenu-title {
    padding: 0px;
    margin: 0px;
  }

  & .ant-menu-submenu .ant-menu-submenu {
    .ant-menu-submenu-title {
      padding-left: 54px !important;
    }
  }

  & .ant-menu-item:not(.no-child) {
    margin-bottom: ${(props) => !(props.isfavouritemenu === 'true') && '0px !important'};
    margin-top: 0px !important;
    padding-left: 54px !important;
    height: 40px !important;
    background-color: ${(props) => (props.isfavouritemenu === 'true' ? '#bdbdbdbd' : '#ffffff')};
    color: ${(props) => props.isfavouritemenu === 'true' && props.theme.fontColor.medium};
  }

  & .anticon {
    margin-right: 16px !important;
    font-size: 14px !important;
  }

  & .ant-menu-submenu-arrow::before,
  & .ant-menu-submenu-arrow::after {
    background: #dddddd !important;
  }

  & .ant-menu-submenu-title,
  & .ant-menu-submenu-title:hover {
    font-size: ${(props) => props.theme.fontSizes.sm};
    color: ${(props) => props.theme.fontColor.medium};
  }

  & .ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
  & .ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
  & .ant-menu-submenu-inline > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before,
  & .ant-menu-submenu-inline > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after {
    background: ${(props) => props.theme.colors.darkGrey};
  }

  & .ant-menu-title-content {
    margin: 0 !important;
  }

  ${(props: IMenuStyled) =>
    props?.iscollapsed &&
    `
    & .ant-menu-submenu-selected {
      position: relative;
      background: #EFF9FF;

      .ant-menu-submenu-title {
       color: #2A62FE;
      }
      :before {
        content: '';
        z-index: 20;
        border: 2px solid #2A62FE;
        background: #2A62FE;
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 8px;
        height: 100%;
      
    }
  `}
`

interface Props {
  iscollapsed: number
  handleTriggerClick: () => void
  menus: IMenu[]
  favoriteMenus: IMenu[]
  route: string[]
  onClickFavoriteMenu: (favoriteMenu: string) => void
}

interface IMenuStyled extends PropsTheme {
  iscollapsed: number
  isfavouritemenu?: string
}

export const LeftMenu: React.FC<Props> = ({
  menus,
  route,
  iscollapsed,
  handleTriggerClick,
  favoriteMenus,
  onClickFavoriteMenu
}) => {
  const router = useRouter()

  const handleRoute = (e) => {
    if (e?.key === '/product') localStorage.setItem('query', '')
  }

  return (
    <>
      <OldRow justify="center" className="headerMenu" align="middle">
        {!iscollapsed && (
          <Col flex="auto">
            <Button
              className="btnHome"
              onClick={() => router.push('/')}
              type="link"
              style={{ justifyContent: 'center' }}
            >
              <ImageWithDefault src="/static/logo/fm_logo_horizontal.svg" alt="fm-icon" width="88" height="24" />
            </Button>
          </Col>
        )}
        <Col>
          <Button
            className="btnTrigger"
            onClick={handleTriggerClick}
            type="text"
            icon={<MenuOutlined style={{ width: '16', height: '14px', alignItems: 'center' }} />}
          />
        </Col>
      </OldRow>
      <MenuStyled
        iscollapsed={iscollapsed}
        mode="inline"
        onClick={handleRoute}
        defaultSelectedKeys={route}
        defaultOpenKeys={route}
        isfavouritemenu="true"
        items={menuItemMapper(favoriteMenus, favoriteMenus, onClickFavoriteMenu)}
      />
      <MenuStyled
        iscollapsed={iscollapsed}
        mode="inline"
        onClick={handleRoute}
        defaultSelectedKeys={route}
        defaultOpenKeys={route}
        items={menuItemMapper(menus, favoriteMenus, onClickFavoriteMenu)}
      />
    </>
  )
}
