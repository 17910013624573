import React, { useRef, useState } from 'react'
import { useRouter } from 'next/router'
import { Layout } from 'antd'
import CapsLockAlert from 'components/CapsLockAlert'
import { BrowserTitle } from 'components/Head'
import { StagingBar } from 'components/Layout/components/StagingBar'
import { TextSm } from 'components/Typography'
import styled from 'styled-components'
import { PropsTheme } from 'theme'
import { BreadcrumbData, NameMenu, PathMenu } from 'utils/constants'

import { BreadcrumbComponent } from '../../../../Breadcrumb'
import { TitlePageHeader } from '../../../components/TitlePageHeader'
import { IMenu } from '../..'

import { Header } from './Header'
import { LeftMenu } from './LeftMenu'

const { Sider, Content } = Layout

interface ISider extends PropsTheme {
  iscollapsed: number
}

export const LayoutWithStyle = styled(Layout)`
  && {
    min-width: 992px;
    max-height: unset;
    min-height: 100vh;
    background-color: ${(props: PropsTheme) => props.theme.colors.whiteOff};

    .ant-layout {
      background-color: ${(props: PropsTheme) => props.theme.colors.whiteOff};
    }
  }
`

const Wrapper = styled.div`
  margin-top: ${(props: { env: string }) => (props.env === 'staging' || props.env === 'development' ? '24px' : '0')};
`

const BottomWrapper = styled.div`
  position: absolute;
  bottom: 24px;
`

export const WrapperContentLayout = styled.div.attrs((props: { iscollapsed: number }) => {
  return { iscollapsed: props?.iscollapsed || 0 }
})`
  width: 100%;
  margin-left: ${(props) => (props.iscollapsed ? '62px' : '230px')};
`

export const WrapperContentHeader = styled.div`
  margin-bottom: 24px;
`

const LayoutContentWithStyled = styled(Layout).attrs((props: ISider) => {
  return { iscollapsed: props?.iscollapsed }
})`
  margin: 120px auto 64px auto;
  min-height: 100vh;
  margin-left: calc(12% - 96px);
  margin-right: calc(12% - 96px);

  & .ant-layout-content {
    position: relative;
    width: 100%;
  }
`

const SiderWithStyled = styled(Sider).attrs((props: ISider) => {
  return { iscollapsed: props?.iscollapsed }
})`
  background: white !important;
  box-shadow: 1px 0px 8px rgba(0, 0, 0, 0.04);
  && {
    width: ${(props) => (props.iscollapsed ? '62px !important' : '230px !important')};
    height: 100vh;
    max-width: unset !important;
    overflow: auto;
    position: fixed;
    left: 0;
    min-width: unset !important;
    z-index: 20;
  }

  && .ant-menu-item::after {
    left: 0;
    right: unset;
    border: ${(props: PropsTheme) => `2px solid ${props.theme.colors.main}`};
    background: ${(props: PropsTheme) => props.theme.colors.main};
    border-radius: 8px;
  }

  & .ant-menu-item {
    font-size: ${(props: PropsTheme) => props.theme.fontSizes.sm};
    &:hover {
      color: ${(props: PropsTheme) => props.theme.colors.main};
    }
  }

  & .ant-menu-item-selected {
    color: ${(props: PropsTheme) => props.theme.colors.main};
    background-color: ${(props: PropsTheme) => props.theme.colors.hover};
  }

  .headerMenu {
    padding: ${(props) => (props.iscollapsed ? '0' : '0 16px')};
    margin: 12px 0px 26px 0px !important;
    text-align: ${(props) => props.iscollapsed && 'center'};
    background-color: unset !important;

    .ant-btn-text:hover,
    .ant-btn-text:focus {
      background: none;
    }

    .btnHome {
      padding: 0;
      display: flex;
      align-items: center;
    }

    .btnTrigger {
      color: ${(props: PropsTheme) => props.theme.fontColor.medium};
    }
  }

  .ant-layout-sider-trigger {
    background: none;
    visibility: hidden;
  }

  .ant-btn-icon-only span {
    margin: 0 !important;
    display: flex;
    justify-content: center;
  }
`
interface IDesktopLayout {
  children: any
  menus: IMenu[]
  favoriteMenus: IMenu[]
  onClickFavoriteMenu: (favoriteMenu: string) => void
}

export const DesktopLayout: React.FC<IDesktopLayout> = ({ children, menus, favoriteMenus, onClickFavoriteMenu }) => {
  const refTriggerButton = useRef(null)
  const { title, route } = children.props
  const [collapsed, setCollapsed] = useState(0)
  const router = useRouter()

  const handleTriggerClick = () => {
    refTriggerButton.current.click()
  }

  return (
    <>
      <CapsLockAlert />
      <StagingBar />
      <Wrapper env={process.env.NEXT_PUBLIC_NODE_ENV}>
        <LayoutWithStyle id="layout-admin">
          <SiderWithStyled
            breakpoint="lg"
            collapsible
            onCollapse={(collapsed) => {
              setCollapsed(collapsed ? 1 : 0)
            }}
            trigger={<div ref={refTriggerButton} />}
            iscollapsed={collapsed}
          >
            <LeftMenu
              handleTriggerClick={handleTriggerClick}
              iscollapsed={collapsed}
              menus={menus}
              route={route}
              favoriteMenus={favoriteMenus}
              onClickFavoriteMenu={onClickFavoriteMenu}
            />
            {process.env.GIT_COMMIT_HASH && (
              <BottomWrapper>
                <TextSm color="LightGray">Commit {process.env.GIT_COMMIT_HASH.substring(0, 8)}</TextSm>
              </BottomWrapper>
            )}
          </SiderWithStyled>
          <WrapperContentLayout iscollapsed={collapsed}>
            <Header iscollapsed={collapsed} />
            <LayoutContentWithStyled iscollapsed={collapsed}>
              <WrapperContentHeader>
                <TitlePageHeader
                  title={BreadcrumbData?.[router?.pathname]?.[BreadcrumbData?.[router?.pathname]?.length - 1]?.label}
                />
                <BreadcrumbComponent
                  breadcrumbs={
                    BreadcrumbData?.[router?.pathname] || [{ label: NameMenu.HOME, href: PathMenu.WORKSPACE_SHOW }]
                  }
                />
              </WrapperContentHeader>
              <BrowserTitle page={title} />
              <Content>{children}</Content>
            </LayoutContentWithStyled>
          </WrapperContentLayout>
        </LayoutWithStyle>
      </Wrapper>
    </>
  )
}
