import { useContext } from 'react'
import { TextSmMinus } from 'components/Typography'
import { ThemeContext } from 'styled-components'
import { DarkTheme, LightTheme } from 'theme'

import { User } from '@auth0/auth0-react'

require('dayjs/locale/th')

export interface SuperAdminRole {
  id: number
  superAdmin: boolean
  email: string
  note?: string
}

export const superAdmins: SuperAdminRole[] = [
  {
    id: 1,
    superAdmin: true,
    email: 'patcharapoom@futuremakers.co.th',
    note: 'poom engineer'
  },
  {
    id: 2,
    superAdmin: true,
    email: 'kritsaki@futuremakers.co.th',
    note: 'big lead engineer'
  },
  {
    id: 9,
    superAdmin: true,
    email: 'nattaleeya@futuremakers.co.th',
    note: 'nan engineer'
  },
  {
    id: 3,
    superAdmin: true,
    email: 'rujipart@futuremakers.co.th',
    note: 'tle lead engineer'
  },
  {
    id: 4,
    superAdmin: true,
    email: 'tanapruk@futuremakers.co.th',
    note: 'trust dev'
  },
  {
    id: 5,
    superAdmin: true,
    email: 'wasanint@futuremakers.co.th',
    note: 'wasan'
  },
  {
    id: 6,
    superAdmin: true,
    email: 'wasan@futuremakers.co.th',
    note: 'wasan'
  },
  {
    id: 8,
    superAdmin: true,
    email: 'tintkoko@futuremakers.co.th',
    note: 'tint dev'
  },
  {
    id: 10,
    superAdmin: true,
    email: 'sithiyapor@futuremakers.co.th',
    note: 'furn marketing lead'
  },
  {
    id: 11,
    superAdmin: true,
    email: 'ananya@futuremakers.co.th',
    note: 'pan creative'
  },
  {
    id: 13,
    superAdmin: true,
    email: 'jese@futuremakers.co.th',
    note: 'jese dev'
  },
  {
    id: 15,
    superAdmin: true,
    email: 'korrawee@futuremakers.co.th',
    note: 'wave dev'
  },
  {
    id: 16,
    superAdmin: true,
    email: 'chaichana@futuremakers.co.th',
    note: 'chaichana engineer'
  },
  {
    id: 17,
    superAdmin: true,
    email: 'kiattichai@futuremakers.co.th',
    note: 'jack engineer'
  },
  {
    id: 18,
    superAdmin: true,
    email: 'atcharaporn@futuremakers.co.th',
    note: 'Som marketing'
  },
  {
    id: 19,
    superAdmin: true,
    email: 'ratnapat@futuremakers.co.th',
    note: 'CS Lead'
  },
  {
    id: 20,
    superAdmin: true,
    email: 'phatsanate@futuremakers.co.th',
    note: 'win engineer'
  },
  {
    id: 21,
    superAdmin: false,
    email: 'myintmyatthein@futuremakers.co.th',
    note: 'developer'
  },
  {
    id: 22,
    superAdmin: true,
    email: 'patavee@futuremakers.co.th',
    note: 'engineer'
  },
  {
    id: 23,
    superAdmin: true,
    email: 'tananan@futuremakers.co.th',
    note: 'engineer beer'
  },
  {
    id: 24,
    superAdmin: true,
    email: 'phoowanat@futuremakers.co.th',
    note: 'BA'
  },
  {
    id: 25,
    superAdmin: true,
    email: 'nicharee@futuremakers.co.th',
    note: 'Marketing/Nocnoc monitorer'
  }
]
/**
 * check user role is superAdmin
 */
export const isSuperAdminUser = (user: User): boolean => {
  return !!superAdmins.find((admin) => String(admin?.email).toLowerCase() === String(user?.email).toLowerCase())
}

interface SuperAdminBadgeProps {
  user: User
  message?: string
  messageNonAdmin?: string
}
export const SuperAdminBadge: React.FC<SuperAdminBadgeProps> = ({ user, message, messageNonAdmin }) => {
  const themeContext = useContext(ThemeContext) as LightTheme | DarkTheme
  const isAdmin = isSuperAdminUser(user)
  if (!isAdmin && messageNonAdmin) {
    return <>{messageNonAdmin}</>
  }
  if (!isAdmin) {
    return <></>
  }

  return (
    <TextSmMinus as="span" color={themeContext.fontColor.success}>
      admin {message}
    </TextSmMinus>
  )
}
