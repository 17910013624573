import { useContext } from 'react'
import * as React from 'react'
import { Badge } from 'antd'
import { GetnotificationsMeta } from 'graphql-shared'
import { ThemeContext } from 'styled-components'
import { DarkTheme, LightTheme } from 'theme'

import { BellOutlined } from '@ant-design/icons'

interface Props {
  getNotificationsMeta?: GetnotificationsMeta
}

export const BadgeNotification: React.FC<Props> = ({ getNotificationsMeta }) => {
  const { all, outdated } =
    getNotificationsMeta?.getNotificationsMeta.length > 0 && getNotificationsMeta?.getNotificationsMeta[0]

  const themeContext = useContext(ThemeContext) as LightTheme | DarkTheme

  return (
    <>
      <Badge color={themeContext.colors.dark} count={all || 0} offset={[0, -2]} overflowCount={99}>
        <BellOutlined style={{ fontSize: 16, color: themeContext.fontColor.medium }} />
      </Badge>

      {outdated > 0 && (
        <Badge
          style={{ left: 24 }}
          color={themeContext.colors.danger}
          count={outdated || 0}
          offset={[0, -27]}
          overflowCount={99}
        ></Badge>
      )}
    </>
  )
}
